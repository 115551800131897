<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddEditTargetSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-target-sidebar-active', val)"
  >
    <template
      #default="{ hide }"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          v-if="editing && editing.target"
          class="mb-0"
        >
          Edit Target
        </h5>
        <h5
          v-else
          class="mb-0"
        >
          Add Target
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>
      <!-- BODY -->
      <validation-observer
        v-else
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validateForm"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="target"
            rules="required|max:11"
          >
            <b-form-group
              label="Target"
              label-for="target"
            >
              <b-form-input
                id="target"
                v-model="targetData.target"
                type="text"
                :state="validationContext.errors.length > 0 ? false:null"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="type"
            rules="required|max:10"
          >
            <b-form-group
              label="Type"
              label-for="TypeSelect"
            >
              <b-form-select
                id="TypeSelect"
                v-model="targetData.type"
                :options="typeOptions"
                class="w-100"
                :state="validationContext.errors.length > 0 ? false:null"
              >
                <template v-slot:first />
              </b-form-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="month"
            rules="required"
          >
            <b-form-group
              label="Month"
              label-for="month"
            >
              <date-picker
                id="month"
                v-model="targetData.month"
                value-type="YYYY-MM"
                type="month"
                name="datepicker"
                :input-class="validationContext.errors.length ? 'is-invalid form-control w-100' : 'form-control w-100'"
              />
              <div
                v-if="validationContext.errors.length"
                class="invalid-feedback"
                style="display:block"
              >
                {{ validationContext.errors[0] }}
              </div>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-2">
            <b-button
              v-if="editing && editing.target"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, email, url, mimes, integer, max, min,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddEditTargetSidebarActive',
    event: 'update:is-add-edit-target-sidebar-active',
  },
  props: {
    editing: {
      type: Object,
      default() {
        return null
      },
    },
    isAddEditTargetSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      typeOptions: [
        { text: 'Usa', value: 'us' },
        { text: 'Canada', value: 'ca' },
        { text: 'Subscriptions', value: 'subs' },
        { text: 'Rides', value: 'rides' },
        { text: 'Odometers', value: 'odometers' },
        { text: 'Buyers', value: 'buyers' },
        { text: 'Users', value: 'users' },
      ],
      targetData: {
        month: null,
        type: null,
        target: null,
      },
      max,
      min,
      required,
      alphaNum,
      email,
      url,
      mimes,
      integer,
    }
  },
  watch: {
    editing() {
      if (this.editing && this.editing.target) {
        this.targetData = {
          month: `${this.editing.year}-${this.editing.month.toString().padStart(2, '0')}`,
          type: this.editing.type,
          target: this.editing.target,
        }
      }
    },
  },
  methods: {
    resetForm() {
      this.targetData = {
        month: null,
        type: null,
        target: null,
      }
      this.$emit('close', 1)
    },
    validateForm() {
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.loading = true

            const [year, month] = this.targetData.month.split('-')

            const localTargetDataLocal = {
              year,
              month,
              type: this.targetData.type,
              target: this.targetData.target,
            }

            this.$http.post(this.$resources.admins.target, localTargetDataLocal)
              .then(response => {
                const { status } = response
                if (status === 200) {
                  this.$notify({ type: 'success', title: 'target', text: this.editing ? 'Target edited Successfully' : 'Target added Successfully' })
                  this.$emit('refetch-data', 1)
                  this.$emit('update:is-add-new-user-sidebar-active', false)
                } else if (status === 422) {
                  this.$notify({ type: 'danger', title: 'Validation error', text: 'Email is used' })
                  this.$refs.projectForm.setErrors({ email: ['Email is usd.'] })
                } else {
                  this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
                }
                this.loading = false
              })
              .catch(error => {
                if (error.response && error.response.status === 422) {
                  this.$notify({ type: 'danger', title: 'Validation error', text: 'Email is used' })
                } else {
                  this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
                }
                this.loading = false
              })
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.mx-input-wrapper,.mx-datepicker {
  width:100% !important;
}
</style>
