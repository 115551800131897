<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-content-center"
    >
      <b-spinner label="Loading..." />
    </div>
    <b-card
      v-else
    >
      <add-edit-target
        :is-add-edit-target-sidebar-active.sync="isAddEditTargetSidebarActive"
        :editing="target"
        @close="target = null"
        @refetch-data="load"
      />
      <b-row>
        <b-col>
          <b-button
            class="float-right"
            @click="isAddEditTargetSidebarActive = true"
          >
            <feather-icon icon="PlusIcon" />
            Add New
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="card-title">
          Target
        </b-col>
      </b-row>
      <new-table
        :export-file-name="exportFileName"
        :columns="columns"
        :items="items"
        table-name="targets-list"
        @edit="edit"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
  VBTooltip,
} from 'bootstrap-vue'
import addEditTarget from '@/views/Target/addEditTarget.vue'
import Moment from 'moment'
import NewTable from '@/views/new-table.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    addEditTarget,
    NewTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      moment: Moment,
      exportFileName: `targets_list_${Moment(new Date()).format('YYYY-MM-DD')}`,
      filter: '',
      oldFilter: null,
      loading: false,
      target: null,
      items: [],
      fields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'target', label: 'Target', sortable: true },
        { key: 'month', label: 'Month', sortable: true },
        { key: 'year', label: 'Year', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
      columns: [
        {
          label: 'Type',
          data: 'type',
        },
        {
          label: 'Target',
          data: 'target',
        },
        {
          label: 'Month',
          data: 'month',
        },
        {
          label: 'Year',
          data: 'year',
        },
        {
          label: 'Actions',
          data: 'actions',
          filterable: false,
          custom_template: {
            template_name: 'targets.list.actions',
          },

        },
      ],
      isAddEditTargetSidebarActive: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load(filter = null) {
      this.isAddEditTargetSidebarActive = false
      this.loading = true
      this.$http.get(this.$resources.admins.target, {
        params: {
          filter,
        },
      }).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.oldFilter = filter || null
          this.items = data.rows
          this.loading = false
        }
      })
    },
    edit(item) {
      this.target = item
      this.isAddEditTargetSidebarActive = true
    },
  },
}
</script>
